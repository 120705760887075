/*------- 14. Brand logo style  -------*/

.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;
    img {
      transition: all 0.5s ease 0s;
      width: 250px;
      height: auto;
      border-radius: 10px;
    }
  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}

.swiper-autoheight, .swiper-autoheight .swiper-slide {
  background-color: transparent !important;
}

.brand-logo-active a {
  cursor: curser !important; 
}

.brand-logo-active a:active {
  cursor: curser !important; 
}