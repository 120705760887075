/*--- Product details page  ---*/

.product-large-image-wrapper {
  position: relative;
  .product-img-badges {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      &.pink {
        background-color: #fa6bff;
      }
      &.purple {
        background-color: $theme-color;
      }
    }
  }

  .lightgallery-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    padding: 0;
    border: none;
    background: none;
  }

  &--slider {
    .ht-swiper-button-nav {
      font-size: 50px;

      visibility: hidden;

      padding: 0;

      transition: 0.3s;

      opacity: 0;
      color: #333;
      border: none;
      background: none;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
    }
  }
}

.shop-area {
  .sticky {
    top: 70px !important;
  }
}

.product-small-image-wrapper {
  .ht-swiper-button-nav {
    visibility: hidden;

    opacity: 0;
    color: #333;
    border: none;
    background: none;
    i {
      font-size: 40px;
    }
  }
  .single-image {
    cursor: pointer;
    min-height: 100px;
    img {
      width: 100%;
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }
  .single-image {
    height: 100%;
    min-height: 100px;
    img {
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }
  .swiper-container-vertical {
    height: 100%;
  }

  &--side-thumb {
    overflow: hidden;

    height: 630px;
    @media #{$lg-layout,
    $md-layout,
    $xs-layout} {
      height: auto;
      margin-top: 15px;
    }
  }
}

.product-image {
  width: 100%;
  height: 77%;
  overflow: hidden;
}

.product-image img {
  max-width: 100%; /* Make sure the image doesn't exceed the container width */
  max-height: 100%; /* Make sure the image doesn't exceed the container height */
  object-fit: contain; /* Maintain aspect ratio and fit inside the container */
}

.product-image {
  position: relative;
}

.small-images {
  margin-top: 10px;
  margin-bottom: 10px;
}

.small-image {
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: opacity 0.3s;
  border-radius: 10px;
  margin: 13px;
}

.small-image:hover {
  opacity: 0.7;
}