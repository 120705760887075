// /*----- Slider style  ------*/
:root {
  --swiper-theme-color: var(--primary-color);
}

.swiper {
  width: 100%;
  height: auto; 
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: #fff;
  width: 100%;
  height: auto; 
}

.swiper-slide img {
  display: block;
  max-width: 100%; 
  height: auto;
  object-fit: cover; 
}

@media (min-width: 768px) {
  .swiper-slide {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .swiper-slide {
    font-size: 22px;
  }
}

.swiper-button-next, .swiper-button-prev{
  color: #ececec; 
}