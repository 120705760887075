/* ---------Product style---------*/

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    position: relative;

    overflow: hidden;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: $theme-secondary-color;
        }
        &.purple {
          background-color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;

      visibility: hidden;

      transition-duration: 0.7s;
      transform: translate(-50%, 20px);

      opacity: 0;
    }
    .product-action {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 50%;

      display: flex;
      align-items: center;
      flex-wrap: wrap;

      width: 100%;

      transform: translateX(-50%);
      > div {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: center;

        height: 48px;

        transition: all 0.5s ease;
        transform: translateY(20px);

        opacity: 0;
        background-color: $theme-color;
        // &:hover {
        //     background-color: #000;
        // }
      }
      .pro-wishlist {
        width: 48px;

        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;

        width: calc(100% - 48px - 48px);

        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        margin: 0;

        transition-delay: 0.2s;

        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-same-action {
        a,
        button {
          font-size: 16px;
          line-height: 48px;

          display: block;

          width: 100%;
          height: 48px;

          text-align: center;
          text-transform: capitalize;

          color: #fff;
          border: none;
          background: none;
          &:hover,
          &.active {
            background-color: #000;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    margin: 20px 0 0;
    h3 {
      font-size: 16px;

      margin: 0;
      padding: 10px;
      line-height: 2.4em;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      min-height: 85px;
    }
    .product-rating {
      margin: 3px 0 3px;
      i {
        font-size: 17px;

        margin: 0 3px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      span {
        font-size: 15px;
        font-weight: 500;

        position: relative;

        margin: 0 9px;

        color: #000;
        &::before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 7px;
          height: 2px;

          content: "";

          background-color: #000;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #8e8e8e;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: none;

    transform: translate(-50%, 0);

    opacity: 1;
  }
  &:hover .product-action > div {
    visibility: visible;

    transform: translateY(0);

    opacity: 1;
  }
}

.modal-dialog {
  width: 960px;
  max-width: 960px;
  margin: 8% auto;
  padding: 35px;
  @media #{$md-layout} {
    width: 720px;
    max-width: 720px;
  }
  @media #{$xs-layout} {
    width: 100%;
    max-width: 100%;
    padding: 35px 15px;
  }
  .modal-header {
    .close {
      cursor: pointer;

      opacity: 1;
      color: #333;
      &:hover {
        color: $theme-color;
      }
    }
  }
  .modal-body {
    padding: 35px 15px;
  }
  .quickview-big-img {
    img {
      width: 100%;
    }
  }
}

.product-details-content {
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-top: 30px;
      margin-left: 0;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 1;

    margin: 0 0 0;

    color: #010101;
  }
  .product-details-price {
    display: flex;
    align-items: center;

    margin: 15px 0 20px;
    span {
      font-size: 24px;

      color: #fe5252;
      &.old {
        font-size: 18px;

        margin-left: 20px;

        text-decoration: line-through;

        color: #333;
      }
    }
  }
  .pro-details-rating-wrap {
    display: flex;
    align-items: center;

    margin: 0 0 17px;
    .pro-details-rating {
      position: relative;

      margin-right: 39px;
      &:before {
        position: absolute;
        top: 4px;
        right: -19px;

        display: none;

        width: 2px;
        height: 15px;

        content: "";

        background-color: #d1d1d1;
      }
      i {
        font-size: 17px;

        margin-right: 5px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    span {
      a {
        font-size: 15px;

        color: $theme-color;
        &:hover {
          color: #000;
        }
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 28px;

    margin: 0;

    color: #333;
  }
  .pro-details-list {
    margin: 20px 0 34px;
    padding: 0 0 37px;

    border-bottom: 1px solid #e5e5e5;
    ul {
      li {
        margin: 0 0 5px;

        color: #333;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .pro-details-size-color {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .pro-details-color-wrap {
      margin-right: 20px;
      @media #{$xs-layout} {
        margin-bottom: 20px;
      }
      @media #{$sm-layout} {
        margin-bottom: 0;
      }
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 15px;
      }
      .pro-details-color-content {
        ul {
          li {
            position: relative;

            display: block;
            float: left;

            width: 14px;
            height: 14px;
            margin-right: 15px;

            cursor: pointer;
            transition: all 0.4s ease 0s;

            border-radius: 50px;
            &:last-child {
              margin-right: 0;
            }
            &.blue {
              border: 1px solid #4798f3;
              background-color: #4798f3;
            }
            &.maroon {
              border: 1px solid #736751;
              background-color: #736751;
            }
            &.gray {
              border: 1px solid #c0c0c0;
              background-color: #c0c0c0;
            }
            &.green {
              border: 1px solid #139c57;
              background-color: #139c57;
            }
            &.yellow {
              border: 1px solid #e28b37;
              background-color: #e28b37;
            }
          }
        }
        &--single {
          position: relative;

          display: inline-block;

          width: 14px;
          height: 14px;
          margin-right: 15px;

          cursor: pointer;
          transition: all 0.4s ease 0s;

          border-radius: 50px;
          &:last-child {
            margin-right: 0;
          }
          input {
            position: absolute;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
          &.blue {
            border: 1px solid #4798f3;
            background-color: #4798f3;
          }
          &.maroon {
            border: 1px solid #736751;
            background-color: #736751;
          }
          &.gray {
            border: 1px solid #c0c0c0;
            background-color: #c0c0c0;
          }
          &.green {
            border: 1px solid #139c57;
            background-color: #139c57;
          }
          &.yellow {
            border: 1px solid #e28b37;
            background-color: #e28b37;
          }
          &.white {
            border: 1px solid #333;
            background-color: #fff;
          }
          &.black {
            border: 1px solid #333;
            background-color: #333;
          }
          &.brown {
            border: 1px solid brown;
            background-color: brown;
          }
          .checkmark {
            position: relative;

            display: inline-block;

            width: 14px;
            height: 14px;
            margin: 0;
            &:after {
              position: absolute;
              top: -7px;
              left: -4px;

              visibility: hidden;

              width: 20px;
              height: 20px;

              content: "";
              transition: 0.3s;

              opacity: 0;
              border: 2px solid #333;
              border-radius: 50%;
            }
          }

          &:hover {
            input {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .pro-details-size {
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 10px;
      }
      .pro-details-size-content {
        ul {
          li {
            display: inline-block;

            margin: 0 2px 0 0;

            list-style: outside none none;
            a {
              font-size: 12px;
              line-height: 1;

              display: inline-block;

              padding: 6px 9px 7px;

              text-transform: uppercase;

              color: #000;
              background-color: #f1f2f6;
              &:hover {
                color: #fff;
                background-color: $theme-color;
              }
            }
          }
        }
        &--single {
          font-size: 12px;
          line-height: 1;

          position: relative;

          display: inline-block;

          margin: 0 5px 0 0;

          text-transform: uppercase;

          color: #000;
          background-color: #f1f2f6;
          &:hover {
            color: #fff;
            background-color: $theme-color;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .size-name {
                color: $white;
                background-color: $theme-color;
              }
            }
          }
          .size-name {
            font-size: 12px;
            font-weight: 400;

            width: 100%;
            margin-bottom: 0;
            padding: 8px;
          }
        }
      }
    }
  }

  .prod-qty-dec{
    border: 1px solid #9cadc8;
    padding: 0 18px;
    border-radius: 25px 0 0 25px;
    height: 35px;
    font-size: 20px;
    font-weight: 700;

  }
  .prod-qty-val{
    padding: 4px 14px;
    border: 1px solid #9cadc8;
    color: #0b2751;
    height: 35px;
    font-size: 20px;
    border-right: none;
    border-left: none;
    font-weight: 700;
  }

  .prod-qty-inc{
    height: 35px;
    font-size: 20px;
    font-weight: 700;
    border: 1px solid #9cadc8;
    border-radius: 0 25px 25px 0;
    padding: 0 18px;
  }

  .pro-details-quality {
    display: flex;
    align-items: center;

    margin-top: 20px;
    // margin-bottom: 34px;
    .cart-plus-minus {
      position: relative;

      display: inline-block;
      overflow: hidden;

      width: 80px;
      height: 60px;
      padding: 0;

      border: 1px solid #e8e8e8;
      @media #{$xs-layout} {
        height: 35px;
      }
      .qtybutton {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        position: absolute;

        float: inherit;

        width: 24px;
        margin: 0;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: #8f8f8f;
        border: none;
        background: none;
      }
      .dec.qtybutton {
        top: 0;
        left: 0;

        height: 60px;
        @media #{$xs-layout} {
          height: 35px;
        }

      }
      .inc.qtybutton {
        top: 0;
        right: 0;

        height: 60px;
        @media #{$xs-layout} {
          height: 35px;
        }
      }
      input.cart-plus-minus-box {
        font-size: 14px;

        float: left;

        width: 80px;
        height: 60px;
        margin: 0;
        padding: 0;

        text-align: center;

        color: #8f8f8f;
        border: medium none;
        background: transparent none repeat scroll 0 0;
        @media #{$xs-layout} {
          height: 35px;
        }
      }
    }
    .pro-details-cart {
      margin: 0 25px 0 10px;
      @media #{$xs-layout} {
        margin: 0 10px 0 10px;
      }
      @media #{$md-layout} {
        margin: 0 17px 0 10px;
      }
      a,
      button {
        font-weight: bold;
        line-height: 1;

        z-index: 99;

        display: inline-block;

        padding: 23px 38px 23px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        background: none;
        background-color: #343538;
        @media #{$xs-layout} {
          // padding: 23px 12px 23px;
          padding: 20px 35px;
          font-size: 0.8rem;
        }
        @media #{$md-layout} {
          padding: 20px 30px;
        }
        &:hover {
          border: none;
        }

        &:disabled {
          cursor: not-allowed;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    .pro-details-wishlist {
      a,
      button {
        font-size: 18px;

        color: #000;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
    .pro-details-compare {
      a,
      button {
        font-size: 18px;

        margin-left: 25px;

        color: #000;
        border: none;
        background: none;
        @media #{$xs-layout} {
          margin-left: 10px;
        }
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  .pro-details-meta {
    display: flex;

    // margin: 0 0 10px;
    span {
      font-size: 15px;

      margin-right: 5px;

      color: #676767;
    }
    ul {
      li {
        display: inline-block;

        margin: 0 10px 0 0;
        a {
          font-size: 15px;

          color: #676767;
          @media #{$xs-layout} {
            font-size: 14px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .pro-details-social {
    margin: 24px 0 0;
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 16px;

          color: #343538;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &.pro-details-slider-content {
    @media #{$xs-layout} {
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .product-details-price {
      justify-content: center;
    }
    p {
      width: 59%;
      margin: 0 auto;
      @media #{$lg-layout} {
        width: 80%;
      }
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$xs-layout} {
        width: 100%;
      }
    }
    .pro-details-rating-wrap {
      justify-content: center;
    }
    .pro-details-size-color {
      justify-content: center;

      text-align: left;
      @media #{$xs-layout} {
        text-align: center;
        & .pro-details-color-content {
          display: flex;
          justify-content: center;
        }
      }
    }
    .pro-details-quality {
      justify-content: center;
    }
    .pro-details-meta {
      justify-content: center;
    }
    .pro-details-social {
      ul {
        justify-content: center;
      }
    }
  }
}

.quickview-wrap {
  .nav-style-1.owl-carousel > .owl-nav div {
    font-size: 20px;

    left: 0;

    color: #333;
    &:hover {
      color: $theme-color;
    }
    &.owl-next {
      right: 0;
      left: auto;
    }
  }
  &:hover .nav-style-1.owl-carousel > .owl-nav div {
    visibility: visible;

    opacity: 1;
  }
}

.product-tab-list-2 {
  @media #{$xs-layout} {
    margin: 0 0 10px;
    position: sticky;
    background-color: white;
    top: 8.5% !important;
    left: 0;
    z-index: 998;
  }
  @media screen and (max-width: 376px) and (min-width: 359px) {
    top: 10.7% !important;
  }

  @media #{$sm-layout} {
    margin: 0 0 0;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;    

        margin: 0;
      padding-top: 20px;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #555;
      border-bottom: 2px solid transparent;
    }
        &:hover {
      color: #000000;
    }
    @media screen and (max-width: 376px) and (min-width: 359px) {
      h4 {
        font-size: 0.9rem;
      }
    }
    &.active {
      h4 {
        color: #000000;
        border-bottom: 2px solid #000000;
      }
    }
  }
}

.grid-style-1 {
  position: relative;

  overflow: hidden;

  border: 1px solid lightgray;

  .product-img {
    position: relative;

    img {
      width: 100%;
    }

    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;

      &.pink {
        color: #fa6bff;
      }

      &.red {
        color: #ff3d2a;
      }

      &.purple {
        color: $theme-color;
      }
    }

    & .default-img {
      transition: all 0.5s ease-in-out;
    }

    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }

    .product-action-2 {
      position: absolute;
      top: 50%;

      display: flex;
      justify-content: center;

      width: 100%;

      transform: translateY(-50%);

      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: $theme-color;

        &:hover,
        &.active {
          background-color: #fa6bff;
        }
      }

      &.product-action-2-red {
        a {
          background-color: #df262b;

          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;

        &.pink {
          color: #fa6bff;
        }

        &.purple {
          color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .product-content-2 {
    display: flex;

    justify-content: center;

    margin: 0px 0 0;

    padding: 10px;
    
    background-color: white;

    height : 70px !important;

    text-align: center;

    align-items: center;

    .title-price-wrap-2 {
      width: 100%;
      h3 {
        line-height: 1.2;

        margin: 0 0 6px;

        display: -webkit-box;

        -webkit-box-orient: vertical;

        -webkit-line-clamp: 2;

        overflow: hidden;

        a {
          font-size: 16px;
          color: #000000;

          display: -webkit-box;

          -webkit-box-orient: vertical;

          -webkit-line-clamp: 2;

          overflow: hidden;

          text-overflow: ellipsis;
          
          word-break: break-word;

          &:hover {
            color: $theme-color;
          }
        }
      }

      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #000000;

          &.old {
            text-decoration: line-through;

            color: #fa6bff;

            &.red {
              color: #ff3d2a;
            }
          }

          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }

          &:last-child:before {
            display: none;
          }
        }
      }

      &.title-price-wrap-2-white {
        h3 {
          a {
            color: #fff;

            &:hover {
              color: $theme-color;
            }
          }
        }

        .price-2 {
          span {
            color: #fff;

            &.old {
              text-decoration: line-through;

              color: #fa6bff;
            }
          }
        }
      }
    }

    .pro-wishlist-2 {

      a,
      button {
        font-size: 16px;

        display: inline-block;

        margin: 4px 0 0 10px;

        color: #929292;
        border: none;
        background: none;

        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }

  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }

  &:hover .default-img {
    visibility: hidden;

    transform: translateX(-100%);
  }

  &:hover .product-action-2 a {
    transform: scaleX(1);
  }

  &:hover .product-action-2 button {
    transform: scaleX(1);
  }

  &.pro-glaucous-color {
    .product-img {
      span.glaucous {
        color: #3d6882;
      }

      .product-action-2 {

        a,
        button {
          background-color: #3d6882;

          &:hover,
          &.active {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }

    .product-content-2 {
      .pro-wishlist-2 a:hover {
        color: #3d6882;
      }

      .title-price-wrap-2 {
        h3 a:hover {
          color: #3d6882;
        }

        .price-2 {
          span.old {
            color: #3d6882;
          }
        }
      }
    }
  }

  &.pro-puce-color {
    .product-img {
      span.puce {
        color: #de3e3e;
      }

      .product-action-2 {

        a,
        button {
          background-color: #de3e3e;

          &:hover {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }

    .product-content-2 {

      .pro-wishlist-2 a:hover,
      .pro-wishlist-2 button:hover {
        color: #de3e3e;
      }

      .title-price-wrap-2 {
        h3 a:hover {
          color: #de3e3e;
        }

        .price-2 {
          span.old {
            color: #de3e3e;
          }
        }
      }
    }
  }
}

.grid-style-2 {
position: relative;

overflow: hidden;

display: block;

border-radius: 10px;

box-shadow: 0 0 7px 1px $theme-color;

transition: transform .3s ease, box-shadow .3s ease;

overflow: hidden;

opacity: .9;

.product-img {
  position: relative;

  img {
    width: 100%;
  }

  span {
    font-size: 14px;
    font-weight: 500;

    position: absolute;
    top: 20px;
    right: 20px;

    &.pink {
      color: #fa6bff;
    }

    &.red {
      color: #ff3d2a;
    }

    &.purple {
      color: $theme-color;
    }
  }

  & .default-img {
    transition: none;
  }

  & .hover-img {
    position: absolute;
    top: 0;
    left: 0;

    visibility: hidden;

    width: 100%;

    transition: none;
    transform: translateX(100%);

    opacity: 0;
  }

  .product-action-2 {
    position: absolute;
    top: 50%;

    display: flex;
    justify-content: center;

    width: 100%;

    transform: translateY(-50%);

    a,
    button {
      font-size: 16px;
      line-height: 42px;

      display: inline-block;

      width: 42px;
      height: 42px;
      margin: 0 3px;

      transition: all 0.4s ease-in-out;
      transform: scaleX(0);
      text-align: center;

      color: #fff;
      border: none;
      border-radius: 50px;
      background: none;
      background-color: $theme-color;

      &:hover,
      &.active {
        background-color: #fa6bff;
      }
    }

    &.product-action-2-red {
      a {
        background-color: #df262b;

        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }

  &-badges {
    position: absolute;
    top: 12px;
    right: 12px;
    & > span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      position: static;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      border-radius: 3px;

      &.pink {
        color: #fa6bff;
      }

      &.purple {
        color: $theme-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.product-content-2 {
  display: flex;

  justify-content: center;

  margin: 0px 0 0;

  padding: 10px 5px;
  
  background-color: $theme-color;

  .title-price-wrap-2 {
    h3 {
      line-height: 1;

      margin: 0 0 6px;

      a {
        font-size: 16px;

        color: white;

        &:hover {
          color: #000000;
        }
      }
    }

    .price-2 {
      span {
        font-size: 15px;

        position: relative;

        margin-right: 17px;

        color: #000000;

        &.old {
          text-decoration: line-through;

          color: #fa6bff;

          &.red {
            color: #ff3d2a;
          }
        }

        &::before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }

        &:last-child:before {
          display: none;
        }
      }
    }

    &.title-price-wrap-2-white {
      h3 {
        a {
          color: #fff;

          &:hover {
            color: $theme-color;
          }
        }
      }

      .price-2 {
        span {
          color: #fff;

          &.old {
            text-decoration: line-through;

            color: #fa6bff;
          }
        }
      }
    }
  }

  .pro-wishlist-2 {

    a,
    button {
      font-size: 16px;

      display: inline-block;

      margin: 4px 0 0 10px;

      color: #929292;
      border: none;
      background: none;

      &:hover,
      &.active {
        color: $theme-color;
      }
    }
  }
}

&:hover .hover-img {
  visibility: visible;

  transform: translateX(0);

  opacity: 1;
}

&:hover .default-img {
  visibility: hidden;

  transform: translateX(-100%);
}

&:hover .product-action-2 a {
  transform: scaleX(1);
}

&:hover .product-action-2 button {
  transform: scaleX(1);
}

&.pro-glaucous-color {
  .product-img {
    span.glaucous {
      color: #3d6882;
    }

    .product-action-2 {

      a,
      button {
        background-color: #3d6882;

        &:hover,
        &.active {
          color: #000;
          background-color: #fff;
        }
      }
    }
  }

  .product-content-2 {
    .pro-wishlist-2 a:hover {
      color: #3d6882;
    }

    .title-price-wrap-2 {
      h3 a:hover {
        color: #3d6882;
      }

      .price-2 {
        span.old {
          color: #3d6882;
        }
      }
    }
  }
}

&.pro-puce-color {
  .product-img {
    span.puce {
      color: #de3e3e;
    }

    .product-action-2 {

      a,
      button {
        background-color: #de3e3e;

        &:hover {
          color: #000;
          background-color: #fff;
        }
      }
    }
  }

  .product-content-2 {

    .pro-wishlist-2 a:hover,
    .pro-wishlist-2 button:hover {
      color: #de3e3e;
    }

    .title-price-wrap-2 {
      h3 a:hover {
        color: #de3e3e;
      }

      .price-2 {
        span.old {
          color: #de3e3e;
        }
      }
    }
  }
}
}

.grid-style-2:hover {
  box-shadow: 0 10px 20px #000000;
  transform: translateY(-5px);
  opacity: 1;
}

.product-quickview-modal-wrapper {
  .modal-dialog {
    margin: 5% auto;
    padding: 0;
  }
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.product-image-container {
  overflow: hidden;
  position: relative;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  padding: 30px;
}

.product-image.zoomed {
  transform: scale(2);
  cursor: move;
  padding: 30px;
}

.view-more{
  border: none;
  background-color: #000;
  color: #fff;
  padding: 15px 25px;
  font-size: 1.1rem;
  font-weight: 600;
}

.margin-left-list {
  margin-left: 180px !important;
}

@media (max-width: 767px) {
  .margin-left-list {
    margin-left: 0px !important;
  }
}
