.blog-wrap {
  .blog-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
    .blog-category-names {
      position: absolute;
      top: 10px;
      left: 10px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        display: inline-block;
        margin-right: 10px;
        padding: 2px 10px 5px;
        color: #fff;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: $theme-color;
        }
        &.red {
          background-color: #c61a32;
        }
        &::last-child {
          margin-right: 0;
        }
      }
      &--style2 {
        right: 10px;
        left: auto;
      }
    }
  }
  .blog-content-wrap {
    text-align: center;
    .blog-content {
      position: relative;
      z-index: 99;
      display: inline-block;
      width: 320px;
      max-width: 100%;
      margin: -64px 0 0;
      padding: 14px 44px;
      background-color: #fff;
      @media #{$lg-layout} {
        margin: -50px 0 0;
        padding: 14px 30px;
      }
      @media #{$xs-layout} {
        margin: -50px 0 0;
        padding: 14px 30px;
      }
      @media #{$sm-layout} {
        padding: 14px 15px;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin: 0 0 5px;
        @media #{$sm-layout} {
          font-size: 18px;
          line-height: 27px;
        }
      }
      span {
        font-style: italic;
        color: #666;
        a {
          color: #666;
          &:hover {
            color: $theme-color;
          }
        }
      }
      &--style2 {
        width: 100%;
        min-height: 150px;
        margin-top: 10px;
        padding: 21px 15px 30px 15px;

        background: #f6f3f3;
      }
    }
  }
  &:hover .blog-img img {
    transform: scale(1.2);
  }
}
  .blog-imgs {
    width: 100%;
    object-fit: cover;
  }